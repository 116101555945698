import { Box, Card, styled, TextField } from "@mui/material";

export const FlexAlignCenter = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const LoginRoot = styled(FlexAlignCenter)({
  background: "#edf6fa",
  minHeight: "100vh !important",
  "& .card": {
    display: "flex",
    alignItems: "center",
    maxWidth: 800,
    minHeight: 400,
    borderRadius: 12,
    margin: "1rem",
  },
});

export const StyledCard = styled(Card)({
  minHeight: 300,
  minWidth: 600,
  maxWidth: 800,
  margin: "1rem",
  borderRadius: 12,
  boxShadow: "rgb(0 0 0 / 6%) 0px 3px 3px -2px",
  "& .cardHolder": { background: "#1A2038" },
});

export const ContentBox = styled(Box)({
  height: "100%",
  padding: "32px",
  position: "relative",
  background: "rgba(0, 0, 0, 0.01)",
});

const commonInputStyles = {
  minWidth: "400px !important",
  maxWidth: "400px !important",
  "& label": { fontSize: "12px" },
  "& label.MuiInputLabel-shrink": { marginTop: "0px", fontSize: "14px" },
  "& .MuiOutlinedInput-root": {
    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
      padding: "8px 20px !important",
    },
  },
};

export const StyledTextField = styled(TextField)(() => ({
  ...commonInputStyles,
}));

export const SmallTextField = styled(TextField)(() => ({
  ...commonInputStyles,
  minWidth: "300px !important",
  maxWidth: "300px !important",
}));
