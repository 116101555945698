import "../styles/businessProfile.css";
import { useEffect, useState } from "react";
import { decryptArray } from "../utils/encryption";
import { getBusinessProfile } from "../api/businessProfile";
import { useNavigate } from "react-router-dom";
import { getAccessToken, isValidToken } from "../auth/auth";
import LoadingSpinner from "../components/Spinner";

const BusinessProfile = () => {
  const navigate = useNavigate();
  const [customerData, setCustomerData] = useState({});
  const [fornituraData, setFornituraData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let isValid = isValidToken();
      if (!isValid) {
        setIsLoading(false);
        localStorage.clear();
        navigate("/");
      }
      let token = getAccessToken();
      if (token) {
        let result = await getBusinessProfile(token);
        if (result && result.success === true) {
          setErrorMsg("");
          let businessData = decryptArray(result.data);
          setFornituraData(businessData?.fornituraData || []);
          setCustomerData(businessData?.customerData || {});
          setTimeout(() => {
            setIsLoading(false);
          }, 3000);
        } else {
          setErrorMsg("Something went wrong!");
          setTimeout(() => {
            setIsLoading(false);
          }, 3000);
        }
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div>
      <div className="business-navbar">
        <div className="portal-title">Metamer Portal</div>
        <div className="logout-container">
          <button className="logout-btn" onClick={handleLogout}>
            Sign Out
          </button>
        </div>
      </div>
      <div style={{ display: isLoading ? "" : "none" }} className="loader-div">
        <LoadingSpinner />
      </div>

      <div
        className="text-container"
        style={{ display: isLoading || errorMsg ? "none" : "" }}
      >
        <div style={{ width: "90%" }}>
          <div className="business-title">Customer profile</div>
          <div style={{ margin: "20px" }}>
            {[
              "SOG_COD",
              "SOG_TIPO",
              "SOG_NOME",
              "SOG_COGNOME",
              "SOG_RAGIONE_SOCIALE",
            ].map((item, index) => (
              <div
                key={index}
                style={{
                  fontWeight: "400",
                  fontSize: "12px",
                  margin: "10px",
                }}
              >
                <b
                  style={{
                    display: "inline-block",
                    fontWeight: "500",
                    fontSize: "12px",
                    marginRight: "10px",
                  }}
                >
                  {item} :
                </b>
                {customerData[item] ? customerData[item] : ""}
              </div>
            ))}
          </div>
          <div className="business-title">Fornitura details</div>
          <table
            border="1"
            style={{
              display: errorMsg ? "none" : "",
              width: "90%",
              borderCollapse: "collapse",
              boxShadow: "0 0 20px rgba(0, 0, 0, 0.15)",
            }}
          >
            <thead>
              <tr>
                <th className="thStyle">ID</th>
                <th className="thStyle">COD_FORNITURA</th>
                <th className="thStyle">POD_PDR</th>
                <th className="thStyle">STATO_FORNITURA</th>
              </tr>
            </thead>
            <tbody>
              {fornituraData.map((item, index) => (
                <tr key={index}>
                  <td className="tdStyle">{item.id}</td>
                  <td className="tdStyle">{item.COD_FORNITURA}</td>
                  <td className="tdStyle">{item.POD_PDR}</td>
                  <td className="tdStyle">{item.STATO_FORNITURA}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div
        className="error-container"
        style={{ display: errorMsg && !isLoading ? "" : "none" }}
      >
        <div className="error-main-title">Oops !</div>
        <div className="error-sub-title"> {errorMsg}</div>
      </div>
    </div>
  );
};
export default BusinessProfile;
