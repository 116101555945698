import axios from "axios";

export const getBusinessProfile = async (token) => {
  try {
    const result = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL}/business-profile`,
    });
    return result.data;
  } catch (error) {
    console.log(error);
    return { success: false };
  }
};
