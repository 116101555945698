import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { LoginRoot, StyledCard } from "../components/CommonContainers";
import "../styles/login.css";
import { useEffect, useState } from "react";
import { Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { decryptArray, encryptContent } from "../utils/encryption";
import { sendOtp } from "../api/login";

const ChooseMobile = () => {
  const navigate = useNavigate();
  const { sessionId } = useParams();

  const [contactArray, setContactArray] = useState([]);
  const [mobileData, setMobileData] = useState({ mobile_number: "" });
  const [errorMsg, setErrorMsg] = useState("");
  const [sendOtpLoading, setSendOtpLoading] = useState(false);

  useEffect(() => {
    function formatPhoneNumber(number) {
      const numStr = number.toString();
      if (numStr.length >= 10) {
        return `${numStr[0]}**${numStr[3]}${numStr[4]}****${
          numStr[numStr.length - 1]
        }`;
      } else {
        return number;
      }
    }
    if (sessionId) {
      let encryptedMob = localStorage.getItem(`sid_${sessionId}`);
      if (encryptedMob) {
        let mobileNumbers = decryptArray(encryptedMob);
        if (Array.isArray(mobileNumbers) && mobileNumbers.length > 0) {
          let contactToDisplay = mobileNumbers.map((item) => ({
            id: item,
            value: formatPhoneNumber(item),
          }));

          setContactArray(contactToDisplay);
          setMobileData((previousState) => ({
            ...previousState,
            mobile_number: mobileNumbers[0] || "",
          }));
        }
      }
    }
  }, [sessionId]);

  const handleSendOtp = async (data) => {
    try {
      setErrorMsg("");
      setSendOtpLoading(true);
      let postData = {};
      postData.mobile_number = data.mobile_number;
      postData.sessionId = sessionId;

      let encryptedData = encryptContent(postData);
      let result = await sendOtp(encryptedData);
      setSendOtpLoading(false);
      if (result.success === true) {
        let encryptedMob = encryptContent([data.mobile_number]);
        if (encryptedMob) {
          localStorage.setItem(`smn_${sessionId}`, encryptedMob);
        }
        navigate(`/validate-otp/${sessionId}`);
      } else {
        setErrorMsg(result.message || "Something went wrong");
      }
    } catch (error) {
      console.log("Error", error);
      setSendOtpLoading(false);
      setErrorMsg("Something went wrong");
    }
  };

  const handleFormSubmit = (values) => {
    let postData = values;
    handleSendOtp(postData);
  };

  return (
    <LoginRoot>
      <StyledCard className="choose-mobile-card">
        <div className="choose-mobile-container">
          <div className="choose-mobile-main">
            <div className="form-title">
              Where do you wish to recieve your OTP?
            </div>
            <div className="">
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={mobileData}
                enableReinitialize={true}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="custom-radio-main">
                      <RadioGroup
                        className="custom-radio-container"
                        name="mobile_number"
                        value={values.mobile_number}
                        onChange={handleChange}
                      >
                        {contactArray.map((item, index) => (
                          <FormControlLabel
                            className="mobile"
                            key={index}
                            value={item.id}
                            control={<Radio className="custom-radio-btn" />}
                            label={
                              <span className="custom-radio-label">
                                {item.value}
                              </span>
                            }
                          />
                        ))}
                      </RadioGroup>
                    </div>
                    <div className="custom-margin-top notification">
                      The selected phone number will be your default
                    </div>
                    <div className="btn-container">
                      <div className="btn-inner-div">
                        <LoadingButton
                          className="custom-margin-top submit-btn"
                          color="primary"
                          type="submit"
                          variant="contained"
                          loading={sendOtpLoading}
                          disabled={sendOtpLoading}
                        >
                          Submit
                        </LoadingButton>
                        <div className="error-div">{errorMsg}</div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </StyledCard>
    </LoginRoot>
  );
};

export default ChooseMobile;
