import {
  LoginRoot,
  SmallTextField,
  StyledCard,
} from "../components/CommonContainers";
import "../styles/login.css";
import { useEffect, useState } from "react";
import { Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { decryptArray, encryptContent } from "../utils/encryption";
import * as yup from "yup";
import { reSendOtp, validateOtp } from "../api/login";
import toast from "react-hot-toast";

const otpSchema = yup.object().shape({
  otp: yup.string().required("*Required"),
});

const ValidateOTP = () => {
  const navigate = useNavigate();
  const { sessionId } = useParams();
  const [selectedMobile, setSelectedMobile] = useState("");
  // eslint-disable-next-line
  const [otpData, setOtpData] = useState({ otp: "" });
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [validateOtpLoading, setValidateOtpLoading] = useState(false);
  const [resendOtpLoading, setResendOtpLoading] = useState(false);

  useEffect(() => {
    function formatPhoneNumber(number) {
      const numStr = number.toString();
      if (numStr.length >= 10) {
        return `${numStr[0]}**${numStr[3]}${numStr[4]}****${
          numStr[numStr.length - 1]
        }`;
      } else {
        return number;
      }
    }
    if (sessionId) {
      let encryptedMob = localStorage.getItem(`smn_${sessionId}`);
      if (encryptedMob) {
        let mobileNumbers = decryptArray(encryptedMob);
        if (Array.isArray(mobileNumbers) && mobileNumbers.length > 0) {
          let contact = formatPhoneNumber(mobileNumbers[0]);
          setSelectedMobile(contact ? `to ${contact}` : "");
        }
      }
    }
    // eslint-disable-next-line
  }, [sessionId]);

  const handleValidateOtp = async (data) => {
    try {
      setErrorMsg("");
      setSuccessMsg("");
      setValidateOtpLoading(true);
      let postData = {};
      postData.otp = data.otp;
      postData.sessionId = sessionId;
      let encryptedData = encryptContent(postData);
      let result = await validateOtp(encryptedData);
      setValidateOtpLoading(false);
      if (result.success === true) {
        let token = result?.data?.token;
        let encryptedtoken = encryptContent(token);
        if (encryptedtoken) {
          localStorage.setItem(`token`, encryptedtoken);
        }

        localStorage.removeItem(`smn_${sessionId}`);
        localStorage.removeItem(`sid_${sessionId}`);
        navigate(`/business-profile`);
      } else {
        setErrorMsg(result.message || "Something went wrong");
      }
    } catch (error) {
      console.log("Error", error);
      setValidateOtpLoading(false);
      setErrorMsg("Something went wrong");
    }
  };

  const handleFormSubmit = (values) => {
    let postData = values;
    handleValidateOtp(postData);
  };

  const handleResendOtp = async () => {
    try {
      setErrorMsg("");
      setSuccessMsg("");
      setResendOtpLoading(true);
      let postData = {};
      postData.sessionId = sessionId;

      let encryptedData = encryptContent(postData);
      let result = await reSendOtp(encryptedData);
      setResendOtpLoading(false);
      if (result.success === true) {
        setSuccessMsg("OTP sent successfully");
        toast.success("OTP sent successfully");
      } else {
        setErrorMsg(result.message || "Something went wrong");
      }
    } catch (error) {
      console.log("Error", error);
      setResendOtpLoading(false);
      setErrorMsg("Something went wrong");
    }
  };

  return (
    <LoginRoot>
      <StyledCard className="validate-otp-card">
        <div className="validate-otp-container">
          <div className="validate-otp-main">
            <div className="form-title">
              {`We have sent an OTP ${selectedMobile}`}
            </div>
            <div className="">
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={otpData}
                enableReinitialize={true}
                validationSchema={otpSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="field-container">
                      <SmallTextField
                        type="text"
                        size="small"
                        name="otp"
                        label="OTP"
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.otp}
                        onChange={handleChange}
                        helperText={touched.otp && errors.otp}
                        error={Boolean(errors.otp && touched.otp)}
                      />
                    </div>
                    <div className="btn-container">
                      <div className="btn-main-container">
                        <LoadingButton
                          className="custom-margin-top submit-btn"
                          color="primary"
                          type="submit"
                          variant="contained"
                          disabled={validateOtpLoading}
                          loading={validateOtpLoading}
                        >
                          Validate
                        </LoadingButton>
                        <LoadingButton
                          className="custom-margin-top submit-btn"
                          color="primary"
                          sx={{
                            textTransform: "lowercase",
                            textDecoration: "underline",
                          }}
                          loading={resendOtpLoading}
                          disabled={resendOtpLoading}
                          onClick={handleResendOtp}
                        >
                          resend OTP
                        </LoadingButton>
                      </div>
                    </div>
                    <div className="success-div error-div">{errorMsg}</div>
                    <div className="success-div">{successMsg}</div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </StyledCard>
    </LoginRoot>
  );
};

export default ValidateOTP;
