import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import {
  LoginRoot,
  StyledCard,
  StyledTextField,
} from "../components/CommonContainers";
import "../styles/login.css";
import { useState } from "react";
import { Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import { userLogin } from "../api/login";
import { encryptContent } from "../utils/encryption";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

const loginSchema = yup.object().shape({
  login_type: yup.string().required("*Required"),
  userName: yup.string().required("*Required"),
});

const Login = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [loginData, setLoginData] = useState({
    login_type: "nickname",
    userName: "",
    email: "",
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);

  const getFieldLabel = (values) => {
    switch (values.login_type) {
      case "nickname":
        return "User name";
      case "customer_code":
        return "Customer Code";
      case "vat_number":
        return "VAT";
      case "fiscal_code":
        return "Fiscal Code";
      default:
        return "User name";
    }
  };

  const handleLogin = async (data) => {
    try {
      setErrorMsg("");
      setLoginLoading(true);
      let postData = {};
      let login_type = data.login_type;
      postData.login_type = login_type;
      if (login_type === "nickname") {
        postData.userName = data.userName;
      } else {
        postData.email = data.email;
        postData[login_type] = data.userName;
      }
      let encryptedData = encryptContent(postData);
      let result = await userLogin(encryptedData);
      setLoginLoading(false);
      if (result.success === true) {
        let sessionId = result.data.sessionId;
        let mobileNumbers = result.data.mobile_numbers;

        let encryptedMob = encryptContent(mobileNumbers);
        if (encryptedMob) {
          localStorage.setItem(`sid_${sessionId}`, encryptedMob);
        }
        if (mobileNumbers.length > 1) {
          navigate(`/send-otp/${sessionId}`);
        } else {
          localStorage.setItem(`smn_${sessionId}`, encryptedMob);
          navigate(`/validate-otp/${sessionId}`);
        }
      } else {
        setErrorMsg(result.message || "Something went wrong");
      }
    } catch (error) {
      console.log("Error", error);
      setLoginLoading(false);
      setErrorMsg("Something went wrong");
    }
  };

  const handleFormSubmit = (values) => {
    let postData = values;
    handleLogin(postData);
  };

  return (
    <LoginRoot>
      <StyledCard>
        <div className="login-main-container">
          <div className="login-main">
            <div className="login-title">Login</div>
            <div className="form-container">
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={loginData}
                validationSchema={loginSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <RadioGroup
                      className="custom-radio-container"
                      name="login_type"
                      value={values.login_type}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="nickname"
                        control={<Radio className="custom-radio-btn" />}
                        label={
                          <span className="custom-radio-label">Nickname</span>
                        }
                      />
                      <FormControlLabel
                        value="customer_code"
                        control={<Radio className="custom-radio-btn" />}
                        label={
                          <span className="custom-radio-label">
                            Customer Code
                          </span>
                        }
                      />
                      <FormControlLabel
                        value="vat_number"
                        control={<Radio className="custom-radio-btn" />}
                        label={<span className="custom-radio-label">VAT</span>}
                      />
                      <FormControlLabel
                        value="fiscal_code"
                        control={<Radio className="custom-radio-btn" />}
                        label={
                          <span className="custom-radio-label">
                            Fiscal Code
                          </span>
                        }
                      />
                    </RadioGroup>

                    <div className="custom-margin-top">
                      <StyledTextField
                        type="text"
                        size="small"
                        name="userName"
                        label={getFieldLabel(values)}
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.userName}
                        onChange={handleChange}
                        helperText={touched.userName && errors.userName}
                        error={Boolean(errors.userName && touched.userName)}
                      />
                    </div>

                    <div
                      className="custom-margin-top"
                      style={{
                        display: values.login_type === "nickname" ? "none" : "",
                      }}
                    >
                      <StyledTextField
                        type="text"
                        size="small"
                        name="email"
                        label="Email"
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.email}
                        onChange={handleChange}
                        helperText={touched.email && errors.email}
                        error={Boolean(errors.email && touched.email)}
                      />
                    </div>

                    <LoadingButton
                      className="custom-margin-top submit-btn"
                      color="primary"
                      type="submit"
                      variant="contained"
                      loading={loginLoading}
                      disabled={loginLoading}
                    >
                      Login
                    </LoadingButton>
                    <div className="error-div">{errorMsg}</div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </StyledCard>
    </LoginRoot>
  );
};

export default Login;
