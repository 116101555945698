import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import BusinessProfile from "./pages/BusinessProfile";
import Login from "./pages/Login";
import ChooseMobile from "./pages/ChooseMobile";
import ValidateOTP from "./pages/ValidateOTP";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Login />} />
          <Route path="/send-otp/:sessionId" element={<ChooseMobile />} />
          <Route path="/validate-otp/:sessionId" element={<ValidateOTP />} />
          <Route path="/business-profile" element={<BusinessProfile />} />
          <Route path="*" element={<h2>Page Not Found</h2>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
