import "../styles/home.css";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { isValidToken } from "../auth/auth";

const Home = () => {
  const navigate = useNavigate();

  const isAuthenticated = isValidToken();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/business-profile");
    } else {
      navigate("/");
    } // eslint-disable-next-line
  }, [isAuthenticated]);

  return <div className="main-container"></div>;
};

export default Home;
