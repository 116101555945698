import CryptoJS from "crypto-js";

export const encryptData = (data) => {
  try {
    let secretKey = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;
    const encryptedData = CryptoJS.AES.encrypt(data, secretKey).toString();
    return encryptedData;
  } catch (error) {
    console.log("encryption err", error);
    return false;
  }
};

export const decryptData = (name) => {
  try {
    let secretKey = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;
    const encryptedData = localStorage.getItem(name);
    if (encryptedData) {
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
      return decryptedData;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Decryption err", error);
    return false;
  }
};

export const encryptContent = (data) => {
  try {
    let secretKey = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;
    let stringData = JSON.stringify(data);
    const encryptedArray = CryptoJS.AES.encrypt(
      stringData,
      secretKey
    ).toString();
    return encryptedArray;
  } catch (error) {
    console.log("encryption err", error);
    return false;
  }
};

export const decryptContent = (encryptedData) => {
  try {
    let secretKey = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;
    if (encryptedData) {
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Decryption err", error);
    return false;
  }
};

export const decryptArray = (encryptedData) => {
  try {
    let secretKey = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;
    if (encryptedData) {
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      const decryptedArray = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedArray;
    } else {
      return [];
    }
  } catch (error) {
    console.log("Decryption err", error);
    return [];
  }
};
