import jwtDecode from "jwt-decode";
import { decryptContent } from "../utils/encryption";

export const isValidToken = () => {
  try {
    let token = localStorage.getItem("token");
    if (!token) return false;
    let accessToken = decryptContent(token);
    if (!accessToken) return false;

    const decodedToken = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp > currentTime;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getAccessToken = () => {
  return localStorage.getItem("token");
};
