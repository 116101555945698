import axios from "axios";
import { decryptContent } from "../utils/encryption";

export const userLogin = async (postData) => {
  try {
    let apiUrl = `${process.env.REACT_APP_API_URL}/auth/login`;
    const res = await axios.post(apiUrl, { data: postData }, {});
    if (!res.data.success) {
      return res.data;
    } else {
      const result = res.data;
      let decryptedData = decryptContent(result.data);
      return { success: true, data: decryptedData };
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return { success: false, message: "Something went wrong" };
    }
  }
};

export const sendOtp = async (postData) => {
  try {
    let apiUrl = `${process.env.REACT_APP_API_URL}/auth/send-otp`;
    const res = await axios.post(apiUrl, { data: postData }, {});
    if (!res.data.success) {
      return res.data;
    } else {
      const result = res.data;
      let decryptedData = decryptContent(result.data);
      return { success: true, data: decryptedData };
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return { success: false, message: "Something went wrong" };
    }
  }
};

export const validateOtp = async (postData) => {
  try {
    let apiUrl = `${process.env.REACT_APP_API_URL}/auth/validate-otp`;
    const res = await axios.post(apiUrl, { data: postData }, {});
    if (!res.data.success) {
      return res.data;
    } else {
      const result = res.data;
      let decryptedData = decryptContent(result.data);
      return { success: true, data: decryptedData };
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return { success: false, message: "Something went wrong" };
    }
  }
};

export const reSendOtp = async (postData) => {
  try {
    let apiUrl = `${process.env.REACT_APP_API_URL}/auth/resend-otp`;
    const res = await axios.post(apiUrl, { data: postData }, {});
    if (!res.data.success) {
      return res.data;
    } else {
      const result = res.data;
      let decryptedData = decryptContent(result.data);
      return { success: true, data: decryptedData };
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return { success: false, message: "Something went wrong" };
    }
  }
};
